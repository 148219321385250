import React, { Component } from 'react';

import Link from '../../Link';
import Product from '../../../models/Product';
import { getPimValue, getPrice, mapEdgesToNodes } from '../../../utils/utils';

class Order extends Component {
  constructor(props) {
    super(props);

    this.initialState = {};

    this.state = {
      ...this.initialState,
    };
  }

  render() {
    const { order, site } = this.props;
    const { shippingAddress } = order;

    const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };
    const lineItems = mapEdgesToNodes(order.lineItems);

    const metaSection = (title, value) => (
      <div className="order__meta-section">
        <span className="order__meta-section__title">{title}</span>
        <span className="order__meta-section__value">{value}</span>
      </div>
    );

    return (
      <li className="order">
        <h3 className="order__order-number">#{order.orderNumber}</h3>
        <div className="order__meta">
          {metaSection('Total', getPrice(site, order.totalPriceV2.amount))}
          {metaSection(
            'Order Date',
            new Date(order.processedAt).toLocaleDateString('en-US', dateOptions)
          )}
          {metaSection('Delivery Status', order.fulfillmentStatus)}
        </div>
        <div className="order__line-items-wrapper">
          <table className="order__line-items">
            <thead>
              <tr className="order__line-item line-item__header">
                <th className="line-item__header-image"></th>
                <th className="line-item__header-product">Product</th>
                <th className="line-item__header-quantity">Quantity</th>
                <th className="line-item__header-subtotal">Sub Total</th>
                <th className="line-item__header-discounts">Discounts</th>
                <th className="line-item__header-totals">Total</th>
              </tr>
            </thead>
            <tbody>
              {lineItems.map((lineItem, i) => (
                <tr key={`${order.id}__${i}`} className="order__line-item">
                  <td>
                    <img src={lineItem.variant?.image?.url} />
                  </td>
                  <td>
                    {lineItem.variant ? (
                      <Link
                        site={site}
                        to={{
                          brandUID: lineItem.variant.product?.vendor
                            ?.toLowerCase()
                            .replace(' ', '-'),
                          ...new Product(
                            {
                              brandUID: 'mustad-fishing',
                              path: lineItem.variant?.product?.handle,
                            },
                            site.siteUID,
                            site
                          ),
                        }}
                      >
                        {lineItem.title}
                      </Link>
                    ) : (
                      lineItem.title
                    )}
                  </td>
                  <td>{lineItem.quantity}</td>
                  <td>{getPrice(site, lineItem.originalTotalPrice.amount)}</td>
                  <td>
                    {lineItem.discountAllocations.length > 0
                      ? getPrice(
                          site,
                          lineItem.discountAllocations.reduce(
                            (acc, discount) =>
                              acc + discount.allocatedAmount.amount,
                            0
                          )
                        )
                      : ''}
                  </td>
                  <td>
                    {getPrice(site, lineItem.discountedTotalPrice.amount)}
                  </td>
                </tr>
              ))}
              <tr className="order__line-item">
                <td></td>
                <td>Shipping</td>
                <td></td>
                <td></td>
                <td></td>
                <td>{getPrice(site, order.totalShippingPriceV2.amount)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </li>
    );
  }
}

export default Order;
